import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/fr"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import TextSection from "../components/ui-sections/text-section"
import ImageGallery from "../components/ui-sections/image-gallery"

const EspaisPage = (props) => {
	const heroText = "Espaces"
	const textSectionText = "Nos chambres sont spacieuses et situées dans notre pays, l'Alt Penedès. Nous avons des salles privées pour des événements spéciaux de groupe, des fêtes de famille ou entre amis, ainsi que des événements d'entreprise. Notre domaine viticole dispose d'une belle table pouvant accueillir 12 personnes pour profiter, parmi plus de 300 références de vins, d'un menu dégustation que Pere vous préparera sur mesure. Nous avons également une salle digestive pour après le déjeuner. Nous ne voulons pas que vous ayez une proie. Nous voulons que vous vous sentiez comme chez vous."
	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Espaces"
	    	lang="fr"
	    	description={textSectionText}
	    />
	    <HeroImage 
	    	titleText={heroText}
	    	image="espais" 
	    />
	    <TextSection textSectionText={textSectionText} />
	    <StaticQuery
	      query={graphql`
	        # Replace this comment with the GraphQL query above
	        {
	          allEspaisImagesFrYaml {
	            edges {
	              node {
	                title
	                image {
	                	id
	                  childImageSharp {
	                    fluid {
	                      aspectRatio
	                      originalImg
	                      # Use gatsby-image later for better user experience
	                      ...GatsbyImageSharpFluid
	        }}}}}}}
	      `}
	      render={data => (
	        <ImageGallery
	          itemsPerRow={[2, 5]} // This will be changed to `[2, 3]` later
	          images={data.allEspaisImagesFrYaml.edges.map(({ node }) => ({
	          	id: node.image.id,
	            ...node.image.childImageSharp.fluid,
	            caption: `${node.title}`,
	          }))}
	        />
	      )}
	    />
	  </Layout>
	)
}

export default EspaisPage
